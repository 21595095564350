/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout-powered-by/index';
import Hero from '../../components/home/hero';
import ContentWithCTA from '../../components/home/content-with-cta';
import LogoGallery from '../../components/home/logo-gallery';
import Testimonials from '../../components/home/testimonials';
import Ticker from '../../components/home/ticker';
import { isBrowser } from '../../utils/functions';
import { useDevice } from '../../hooks';
import SEO from "../../components/seo";
import { getOgImage, setCookie, getCookie } from "./../../utils/functions";

/**
 * PoweredByPage Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const PoweredByPage = ({ pageContext, uri }) => {
	const device = useDevice();
	const { title, poweredByPageMeta, seo } = pageContext;
	const { sectionOne, sectionTwo, sectionSix, sectionSeven, sectionEight } = poweredByPageMeta;

	let isPreviouslyLoaded = false;

	if (isBrowser()) {
		if ('yes' === getCookie('loaded')) {
			isPreviouslyLoaded = true;
		} else {
			// 730 hours == 1 month.
			setCookie('loaded', 'yes', 730);
		}
	}
	const openGraphImage = getOgImage(seo);

	return (
		<Layout title={title} uri={uri} className="powered-by-page" >
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<Hero meta={sectionOne} device={device} showAnimation={!isPreviouslyLoaded} />
			<ContentWithCTA meta={sectionTwo} device={device} />
			<LogoGallery meta={sectionSix} />
			<Testimonials meta={sectionSeven} />
			<Ticker meta={sectionEight} content="The health of society depends on our ability to have quality conversations online" />
		</Layout>
	);
};

PoweredByPage.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default PoweredByPage;
