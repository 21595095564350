/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';

/**
 * Internal dependencies.
 */
import { pageSlug, sanitize, sendContactClickEvent } from '../../../utils/functions';

import './style.scss';
import ResponsivePlayer from "../../reposponsive-player";

/**
 * Home Content Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const ContentWithCTA = ( { meta } ) => {
	const { primaryContent, video, content, ctaButton } = meta; // eslint-disable-line
	const [ ref, inView ] = useInView( {
		threshold: 0.4,
		triggerOnce: true,
	} );

	const containerClasses = classnames( 'home-sections home-content', {
		'animate-section': inView
	} );

	const handleLinkClick = () => {
		sendContactClickEvent( 'Top_Contact', 'HOME' );
	}

	return (
		<section ref={ ref } className={ containerClasses }>
			<div className="row align-center">
				<div className="medium-10 small-12 column">
					<div className="home-content__primary-content" dangerouslySetInnerHTML={ { __html: sanitize( primaryContent ) } } />
					<ResponsivePlayer videoUrl={ video }/>
					{ content && <div className="home-content__content" dangerouslySetInnerHTML={ { __html: sanitize( content ) } } /> }
					{
						ctaButton &&
						<div className="home-content__cta">
							<Link className="home-content__link btn" to={ pageSlug( ctaButton.link.uri ) } onClick={ handleLinkClick }>
								{ ctaButton.text }
							</Link>
						</div>
					}
				</div>
			</div>
		</section>
	);
};

ContentWithCTA.propTypes = {
	meta: PropTypes.object,
	inViewport: PropTypes.bool,
};

ContentWithCTA.defaultProps = {
	meta: {
		primaryContent: '',
		image: {},
		content: '',
		ctaButton: {},
	}
};

export default ContentWithCTA;
