/**
 * External dependencies.
 */
import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useInView } from 'react-intersection-observer';

/**
 * Internal dependencies.
 */
import Image from './../../image/index';
import LinkWithArrow from '../../link-with-arrow';
import { pageSlug, sanitize, handleTestimonialsHeight, calculateTestimonialHeight } from '../../../utils/functions';

import './style.scss';

/**
 * Home Testimonials Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Testimonials = ( { meta } ) => {
	const [ ref, inView ] = useInView( {
		threshold: 0.01,
	} );
	const { testimonials, pageLinkText, pageLink } = meta;
	const sliderRef = createRef();

	useEffect( () => {
		if ( inView ) {
			calculateTestimonialHeight();
			sliderRef.current.slickPlay();
		} else {
			sliderRef.current.slickPause();
		}
	}, [ inView ] ); // eslint-disable-line

	useEffect( () => {

		handleTestimonialsHeight();
		window.addEventListener( 'resize', handleTestimonialsHeight );

		return () => window.removeEventListener( 'resize', handleTestimonialsHeight );

	}, [] );

	const settings = {
		autoplay: true,
		arrows: false,
		dots: false,
		infinite: true,
		speed: 500,
		autoplaySpeed: 5000, // Change slide after 5 seconds.
		slidesToShow: 2,
		slidesToScroll: 1,
		centerPadding: '0',
		swipeToSlide: true,
		touchThreshold: 100,
	};

	return (
		<section ref={ ref } className="home-sections home-testimonials">
			<div className="home-testimonials__lists">
				<button className="home-testimonials__arrows home-testimonials__arrow-prev" onClick={ () => sliderRef.current.slickPrev() } >Left</button>
				<Slider {...settings} ref={ sliderRef } >
					{ testimonials.map( ( testimonial, index ) => (
						<div key={ index } className="home-testimonials__list">
							<div className="home-testimonials__header">
								<figure className="home-testimonials__logo">
									<Image { ...testimonial.logo } lazy={ false } className="home-testimonials__logo-image" />
								</figure>
								<div className="home-testimonials__content" dangerouslySetInnerHTML={ { __html: sanitize( testimonial.content ) } } />
							</div>
							<div className="home-testimonials__meta">
								<figure className="home-testimonials__author">
									<Image { ...testimonial.authorProfilePicture } lazy={ false } className="home-testimonials__author-image" />
								</figure>
								<div className="home-testimonials__details">
									<h4>{ testimonial.authorName }</h4>
									<p>{ testimonial.authorDesignation }</p>
								</div>
							</div>
						</div>
					) ) }
				</Slider>
				<button className="home-testimonials__arrows home-testimonials__arrow-next" onClick={ () => sliderRef.current.slickNext() } >Right</button>
			</div>
			<div className="home-testimonials__more">
				<LinkWithArrow text={ pageLinkText } link={ pageSlug( pageLink.uri ) } />
			</div>
		</section>
	);

};

Testimonials.propTypes = {
	meta: PropTypes.object,
};

Testimonials.defaultProps = {
	meta: {
		testimonials: [],
		pageLink: '',
		pageLinkText: '',
	}
};

export default Testimonials;
